import {
  Box,
  Button,
  Container,
  Flex, Heading,
  HStack, Text,
  useColorMode, VStack
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import {useStaticQuery,graphql } from "gatsby";

import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import QuadKachelnInvRelations from "../components/QuadKachelnInvRelations";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import NewsListItem from "../components/NewsListItem";

import ImgMaskReveal from "../DefinitionComponents/Animation-Components/ImgMaskReveal";
import GImage from "../DefinitionComponents/Utility/GImage";


const News = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });

  const data= useStaticQuery(graphql`
  query getAdhocs{ 
    allWpAdHoc(sort: {fields: date, order: DESC}){
    nodes {
      adHocDate
      adhocTitle
      adHocPDF {
        localFile {
          relativePath
        }
      }
    }
  }
}

  
`)  

const DIDDS = data.allWpAdHoc.nodes;
  return (
    <>
      <Helmet>
        <title>tokentus | Ad-hoc Messages</title>
       
        <meta name="description" content="Ad-hoc Messages provided by tokentus investment AG." />
        <link rel="canonical" href="https:/tokentus.com/adhoc" />
      </Helmet>
      <ImgTextModule
        src={"AdHoc.mp4"}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="55vh"
        mediaOpacity={0.8}
        bgColor="brand.black"
      >
        <Container variant="layoutContainer" mt={8}>
          <Box p={8}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="brand.medgray" fontWeight="normal" fontSize="6xl">
                Ad-hoc Messages.
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color="brand.white"
                mb={4}
                maxW={{ base: "100%", lg: "50%" }}
                fontSize="2xl"
              >
              Relevant Messages. Direct Action.
              </Text>
          
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>
      {/* NEWS */}

     


      <Container
        variant="layoutContainer"
        px={{ base: 0, lg: 10 }}
        py={{ base: 0, lg: 10 }}
      >
       {DIDDS.map((News)=>(  
      <>

     
               <NewsListItem
               datum={News.adHocDate}
               textinhalt={News.adhocTitle}
                pdflink={News.adHocPDF.localFile.relativePath}
                       /> 
  
         
</>
  ))
}
      </Container>
    <QuadKachelnInvRelations/>

    </>
  );
};

export default News;

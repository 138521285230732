import {
  Box,
  Button, Flex, Heading, HStack,
  VStack
} from "@chakra-ui/react";
import moment from "moment";
import { Link } from "gatsby";
import React from "react";
import ImgMaskReveal from "../DefinitionComponents/Animation-Components/ImgMaskReveal";
import GImage from "../DefinitionComponents/Utility/GImage";


function NewsListItem({ datum, textinhalt, pdflink }) {
 const date = moment(datum).format('DD.MM.YYYY');
console.log(date);

  return(
    <Box w={{ base: "100%", lg: "100%" }} mt={1}>
      <Flex wrap="wrap" flexDirection={"row"}  alignContent="stretch" alignItems={"stretch"}>
        <HStack h="100%" display="flex" 
          visibility={{ base: "hidden", lg: "visible" }} display={{base:"none", md:'flex'}}
          w="20%" minH="220px"
          p={0}
          spacing={0}
          bg={"brand.blue"}
        >
          <ImgMaskReveal 
             imgsrc={"newsthumb2.png"}
            initScale={1.5}
            delay={0}
            duration={2}
            threshold={0.4}
            triggerOnce={true}
          />
        </HStack>
        <VStack
          w={{ base: "100%", lg: "80%" }}
          px={10}
          py={10}
          alignItems={"flex-start"}
          bg={"brand.lightgray"}
        >
          <Heading
            fontSize="md"
            fontWeight="normal"
            lineHeight={"none"}
            color="brand.blue"
          >
            {date}
          </Heading>
          <Heading
            w={{ base: "100%", lg: "90%" }}
            fontSize="xl"
            fontWeight="normal"
            lineHeight={"shorter"}
            color="brand.meddarkgray"
          >
            {textinhalt}
          </Heading>
          {pdflink  &&  (
          <a href={`/${pdflink}`}>
            <Button
              color="white"
              size={"lg"}
              fontWeight={"normal"}
              px={6}
              backgroundColor="brand.meddarkgray"
              leftIcon={
                <GImage w="14px" h="14px" src="download-document.svg" />
              }
            >
              PDF Download
            </Button>
          </a>
          )
        
          }
        </VStack>
      </Flex>
    </Box>
  );
}

export default NewsListItem;
